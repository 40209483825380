<template>
  <div class="ibox apartments">
    <div class="ibox-title">
      <h2>{{ $t('hospitality.apartment.list.title') }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (listLoading ? ' sk-loading' : '')">

      <div v-if="listLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <p v-if="showNumberOfRows">Number of rows: {{numberOfRows}}</p>
    
      <b-table 
          outlined striped
          :items="apartments"
          :fields="listFields"
          ref="listItems">
        <template v-slot:cell(ranking)="row">
          <a href="javascript:void(0)" @click="updateRankingUp(row.item)"><i class="fa fa-sort-up"></i></a>
          <a href="javascript:void(0)" @click="updateRankingDown(row.item)"><i class="fa fa-sort-down"></i></a>
        </template> 
        <template v-slot:cell(select)="row">
          <p-check class="p-default p-curve p-primary" color="success" :value="row.item._id" v-model="listItemsSelected"></p-check>
        </template>       
        <template v-slot:cell(title)="row">
          {{getLocalizedText(row.item.title)}}
        </template> 
        <template v-slot:cell(isActivated)="row">
          <i :class="['fa','fa-check',row.item.isActivated ? 'active' : 'disabled']"></i>
        </template> 
        <template v-slot:cell(options)="row">
          <span v-if="isApartmentUpdateAllowed">
            <a href="javascript:void(0)" @click="showUpdateModal(row.item)">{{$t('hospitality.apartment.list.options.update')}}</a>
            /
          </span>
          <span v-if="isApartmentPublishAllowed">
            <a href="javascript:void(0)" @click="showPublishModal(row.item)">{{$t('hospitality.apartment.list.options.publish')}}</a>
            /
          </span>
          <span v-if="isApartmentRemoveAllowed">
            <a href="javascript:void(0)" @click="confirmRemoveItem(row.item)">{{$t('hospitality.apartment.list.options.delete')}}</a>
          </span>
        </template>
      </b-table>

      <button v-if="isApartmentCreateAllowed" class="btn btn-primary" @click="showCreateItemModal()">{{$t('hospitality.apartment.list.create-button')}}</button>

      <b-modal size="xl" ref="createItemModal" :no-enforce-focus="true" :title="$t('hospitality.apartment.create.title')" hide-footer lazy>
        <ApartmentForm v-on:apartment-created="onItemCreated" />
      </b-modal>

      <b-modal size="xl" ref="updateItemModal" :no-enforce-focus="true" :title="$t('hospitality.apartment.update.title', {apartmentID: itemToUpdate._id})" hide-footer lazy>
        <ApartmentForm :apartment="itemToUpdate" v-on:apartment-updated="onItemUpdated" />
      </b-modal>

      <b-modal size="xl" ref="publishItemModal" :title="$t('hospitality.apartment.publish.title', {apartmentID: itemToPublish._id})" hide-footer lazy>
        <PublishApartment :apartment="itemToPublish" />
      </b-modal>

      <b-modal ref="removeItemModal" 
          hide-header
          @ok="removeItem">
        {{$t('hospitality.apartment.list.delete-confirmation', {apartmentID: itemToRemove._id, apartmentTitle: getLocalizedText(itemToRemove.title)})}}
      </b-modal>
    </div>
  </div>
</template>

<style scoped>
  .fa-check.active, .fa-user-circle-o.active {
    color:green;
  }
  .fa-check.disabled, .fa-user-circle-o.disabled {
    color:red;
  }

  
</style>



<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, ComputedRef } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import ApartmentForm from './ApartmentForm.vue';
import PublishApartment from './Publish.vue';
import { languagesTypes } from '@fwk-client/store/types';
import { formatDate } from '@igotweb-node-api-utils/formatter';

import { useApartmentAdmin } from '../../../composables/useApartmentAdmin';

export default defineComponent({
  props: {
  },
  components: {
    ApartmentForm, 
    PublishApartment
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { apartments, isListLoading, isApartmentUpdateAllowed,
    isApartmentCreateAllowed,
    isApartmentRemoveAllowed,
    isApartmentPublishAllowed, removeApartment, updateRanking }  = useApartmentAdmin(props, context);

    const itemToRemove:Ref<any> = ref({});
    const itemToUpdate:Ref<any> = ref({});
    const itemToPublish:Ref<any> = ref({});

    const listLoading:Ref<boolean> = computed(() => {
      return isListLoading.value;
    })

    const listItemsSelected:Ref<any[]> = ref([]);

    const listItems:Ref<HTMLElement|null> = ref(null);
    const removeItemModal:Ref<HTMLElement|null> = ref(null);
    const createItemModal:Ref<HTMLElement|null> = ref(null);
    const updateItemModal:Ref<HTMLElement|null> = ref(null);
    const publishItemModal:Ref<HTMLElement|null> = ref(null);

    const isMultiSelectAllowed:Ref<boolean> = ref(false);
    const showNumberOfRows:Ref<boolean> = ref(false);

    const numberOfRows:Ref<number> = computed(() => {
        return apartments.value.length
    })

    const listFields = [
      {
        key: "select",
        label: "",
        class: (isMultiSelectAllowed.value ? "" : "hidden"),
      },
      {
        key: "ranking",
        label: "",
      },
      {
        key: "title",
        label: "",
      },
      {
        key: "creationDateTime",
        label: "",
        formatter: (value:Date, key:any, item:any) => {
          return formatDate(value, currentLanguageCode.value);
        }
      },
      {
        key: "isActivated",
        label: ""
      },
      {
        key: "summary",
        label: "",
        formatter: (value:any, key:any, item:any) => {
          return app.getLocalizedText(item.summary);
        }
      },
      {
        key: "pictures",
        label: "",
        formatter: (value:any, key:any, item:any) => {
          if(!item.pictures) {
            return 0;
          }
          return item.pictures.length;
        }
      },
      {
        key: "attachements",
        label: "",
        formatter: (value:any, key:any, item:any) => {
          if(!item.attachements) {
            return 0;
          }
          return item.attachements.length;
        }
      },
      {
        key: "options"
      }
    ];

    onMounted(() => {
      listFields[2].label = app.$t('hospitality.apartment.list.headers.title') as string;
      listFields[3].label = app.$t('hospitality.apartment.list.headers.creationDateTime') as string;
      listFields[4].label = app.$t('hospitality.apartment.list.headers.isActivated') as string;
      listFields[5].label = app.$t('hospitality.apartment.list.headers.description') as string;
      listFields[6].label = app.$t('hospitality.apartment.list.headers.thumbnails') as string;
      listFields[7].label = app.$t('hospitality.apartment.list.headers.attachements') as string;
      listFields[8].label = app.$t('hospitality.apartment.list.headers.options') as string;
    })

    const currentLanguageCode:ComputedRef<string> = computed(() => {
      return $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
    })

    const onItemCreated = () => {
      // @ts-ignore
      createItemModal.value.hide()
    }

    const onItemUpdated = (item:any) => {
      itemToUpdate.value = item;
    }
    
    const confirmRemoveItem = (item:any) => {
      itemToRemove.value = item;
      // @ts-ignore
      removeItemModal.value.show()
    }

    const showUpdateModal = (item:any) => {
      itemToUpdate.value = item;
      // @ts-ignore
      updateItemModal.value.show()
    }

    const showCreateItemModal = () => {
      // @ts-ignore
      createItemModal.value.show()
    }

    const removeItem = () => {
      removeApartment(itemToRemove.value._id).then((removed:boolean) => {
        // We reset the user to remove
        itemToRemove.value = {};
      })
    }

    const updateRankingUp = (item:any) => {
      updateApartmentRanking(item, "up");
    }

    const updateRankingDown = (item:any) => {
      updateApartmentRanking(item, "down");
    }

    const showPublishModal = (item:any) => {
      itemToPublish.value = item;
      // @ts-ignore
      publishItemModal.value.show()
    }


    const updateApartmentRanking = (apartment:any, direction:string) => {
      updateRanking(apartment._id, direction).then((response:any) => {
        if(response.updated) {  
          var apartment = response.apartment;
          // We emit event as user is updated
          onItemUpdated(apartment);
        }
      })
    }

    return {
      listLoading,
      apartments,
      listFields,
      listItems,
      listItemsSelected,
      isApartmentUpdateAllowed,
      isApartmentCreateAllowed,
      isApartmentRemoveAllowed,
      isApartmentPublishAllowed,
      showNumberOfRows,
      numberOfRows,
      
      showCreateItemModal,
      createItemModal,
      onItemCreated,
      
      
      removeItemModal,
      itemToRemove,
      confirmRemoveItem,
      removeItem,

      showUpdateModal,
      updateItemModal,
      itemToUpdate,
      updateRankingUp,
      updateRankingDown,
      onItemUpdated,

      itemToPublish,
      publishItemModal,
      showPublishModal
    }
  }
})
</script>