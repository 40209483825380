<template>
  <form role="form" @submit="onFormSubmit" name="apartment">
    <div class="apartment-form">
      <div class="row" v-if="apartmentForm.title">
        <div class="col-lg-12">
          <h2>{{getLocalizedText(apartmentForm.title)}}</h2>
        </div>
      </div>
      <div class="row" v-if="allowCreateFrom">
        <div class="col-lg-12">
          <h3>{{$t('hospitality.apartment.create-from.title')}}</h3>
        </div>
      </div>
      <Select 
        v-if="allowCreateFrom"
        :selectOptions="{
          options: apartments,
          getOptionLabel : apartment => getLocalizedText(apartment.title),
          reduce : apartment => apartment
        }"
        v-bind:value.sync="createFromForm.apartment" 
        id="createFromApartment" 
        :label="$t('hospitality.apartment.create-from.label')" 
        :placeholder="$t('hospitality.apartment.create-from.placeholder')"
        labelAsColumn 
      >
        <template v-slot:buttons>
            <button class="btn btn-primary create-from" type="button" @click="createFromApartment()">{{ $t('hospitality.apartment.create-from.button') }}</button>
        </template>
      </Select>
      <div class="row">
        <div class="col-lg-12">
          <h3>{{$t('hospitality.apartment.info.information')}}</h3>
        </div>
      </div>
      <LocalizedText 
        v-bind:value.sync="apartmentForm.title" 
        id="updateTitle" 
        :label="$t('hospitality.apartment.info.title')" 
        :placeholder="$t('hospitality.apartment.info.title_placeholder')" 
        :labelAsColumn="true"
        :required="false"
        :languageCodes="availableLanguageCodes"
        :rowClass="{'form-group':true, 'row':true, 'update-title':true}"
      />
      <SwitchCheck 
            ref="updateIsActivated"
            v-bind:value.sync="apartmentForm.isActivated" 
            id="updateIsActivated" 
            :label="$t('hospitality.apartment.info.isActivated')" 
            :labelAsColumn="true"
        />
        <LocalizedContent 
          ref="summary"
          v-bind:value.sync="apartmentForm.summary" 
          id="updateSummary" 
          :label="$t('hospitality.apartment.info.summary')" 
          :placeholder="$t('hospitality.apartment.info.summary_placeholder')" 
          :labelAsColumn="true"
          :required="false"
          :languageCodes="availableLanguageCodes"
          :rowClass="{'form-group':true, 'row':true, 'update-summary':true}"
          :options="getSummaryLocalizedContentOptions()" 
        />
      <LocalizedContent 
        ref="description"
        v-bind:value.sync="apartmentForm.description" 
        id="updateDescription" 
        :label="$t('hospitality.apartment.info.description')" 
        :placeholder="$t('hospitality.apartment.info.description_placeholder')" 
        :labelAsColumn="true"
        :required="false"
        :languageCodes="availableLanguageCodes"
        :rowClass="{'form-group':true, 'row':true, 'update-description':true}"
        :options="getDescriptionLocalizedContentOptions()" 
      />
      <div class="hr-line-dashed"></div>
      <div class="row">
        <div class="col-lg-12">
          <h3>{{$t('hospitality.apartment.info.address')}}</h3>
        </div>
      </div>
      <Address 
        v-bind:value.sync="apartmentForm.address"
        :options="addressOptions"
        :labelAsColumn="true"
      />
      <div class="hr-line-dashed"></div>
      <div class="row">
        <div class="col-lg-12">
          <h3>{{$t('hospitality.apartment.info.pictures')}}</h3>
        </div>
      </div>
      <ListPictures 
        v-bind:listPictures.sync="apartmentPictures"
        v-bind:value.sync="apartmentForm.pictures"
        :options="getListPicturesOptions()"
        id="listPictures" 
        :label="$t('hospitality.apartment.info.pictures')" 
        labelAsColumn 
      />
      <div class="hr-line-dashed"></div>
      <div class="row">
        <div class="col-lg-12">
          <h3>{{$t('hospitality.apartment.info.attachements')}}</h3>
        </div>
      </div>
      <ListPictures 
        v-bind:listPictures.sync="apartmentAttachements"
        v-bind:value.sync="apartmentForm.attachements"
        :options="getListAttachementsOptions()"
        id="listAttachements" 
        :label="$t('hospitality.apartment.info.attachements')" 
        labelAsColumn 
      />
    </div>
    <button class="btn btn-primary ladda-button apartment" data-style="zoom-in" type="submit">{{buttonLabel}}</button>
  </form>
</template>

<style>
  .apartment-form .update-description textarea {
    min-height: 100px;
  }
</style>

<script lang="ts">
import { toRefs, Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as Ladda from 'ladda';

import { roles as apiRoles } from '@igotweb/core-api/src/roles';
import { authenticationTypes } from '@fwk-client/store/types';
import LocalizedContent from '@root/src/client/components/panels/input/LocalizedContent.vue';
import LocalizedText from '@root/src/client/components/panels/input/LocalizedText.vue';
import Address from '@fwk-client/components/panels/input/Address.vue';
import SwitchCheck from '@fwk-client/components/panels/input/SwitchCheck.vue';
import ListPictures from '@root/src/client/components/panels/input/ListPictures.vue';
import Select from '@fwk-client/components/panels/input/Select.vue';

import type { LocalizedContentOptions } from '@root/src/client/components/panels/input/content/interfaces';

import { useApartmentAdmin } from '../../../composables/useApartmentAdmin';

export default defineComponent({
  props: {
      apartment: {
        type: Object as PropType<any>,
        required: false
      }
  },
  components: {
    Address,
    LocalizedContent,
    ListPictures,
    SwitchCheck,
    LocalizedText,
    Select
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { apartments, uploadPicture, removePicture, apartmentForm, updateApartmentFormForUpdate, createApartment, updateApartment, isListLoading: isApartmentListLoading, uploadAttachement, removeAttachement, updatePicturesRanking  } = useApartmentAdmin(props, context);

    const availableLanguageCodes = ['fr','en'];

    const { apartment } = toRefs(props);

    const createFromForm:Ref<any> = ref({});

    if(props.apartment) {
      updateApartmentFormForUpdate(props.apartment);
    }

    const apartmentPictures:Ref<any[]> = ref(props.apartment ? props.apartment.pictures : []);
    const apartmentAttachements:Ref<any[]> = ref(props.apartment ? props.apartment.attachements : []);

    const addressOptions = {
      showName: false,
      labelAsColumn: true,
      nbAddressLines: 2,
      showGeolocation: true
    }

    const buttonLabel = computed(() => {
      if(apartment.value) { return app.$t('hospitality.apartment.update.button') }
      else { return 'Create'; }
    })

    var laddaSubmit:Ladda.LaddaButton|null = null;

    onMounted(() => {
      var button:HTMLButtonElement|null = document.querySelector( 'form[name=apartment] button.ladda-button.apartment' );
      laddaSubmit = Ladda.create(button!);
    })

    const allowCreateFrom = props.apartment == undefined;

    const createFromApartment = () => {
      if(createFromForm.value.apartment) {
          updateApartmentFormForUpdate(createFromForm.value.apartment);
      }
    }

    const getSummaryLocalizedContentOptions = () => {
      var allowSourceEditing = $store.getters['authentication/' + authenticationTypes.getters.HAS_USER_ROLE](apiRoles.superadmin);
      var options:LocalizedContentOptions = {
        isTextOnly : true,
        allowWordCount : true,
        allowSourceEditing : allowSourceEditing
      }
      return options;
    }

    const getDescriptionLocalizedContentOptions = () => {
      var allowSourceEditing = $store.getters['authentication/' + authenticationTypes.getters.HAS_USER_ROLE](apiRoles.superadmin);
      var options:LocalizedContentOptions = {
        isTextOnly : false,
        allowWordCount : true,
        allowSourceEditing : allowSourceEditing
      }
      return options;
    }

    const getListPicturesOptions = () => {
      var options:any = {};
      if(apartment.value) {
        options = {
          upload : (picture:File) => {
            return uploadPicture(apartment.value._id, picture);
          },
          remove : (pictureID:string) => {
            return removePicture(apartment.value._id, pictureID);
          },
          updatePicturesRanking : (pictureIndex:number, direction:"up"|"down") => {
            return updatePicturesRanking(apartment.value._id, pictureIndex, direction);
          }
        }
      }
      else {
        options = {
          labels : {
            isInBrowser : app.$t('agency.apartments.apartments.create.picturesInBrowser') as string
          }
        }
      }
      return options;
    }

    const getListAttachementsOptions = () => {
      var options:any = {};
      if(apartment.value) {
        options = {
          upload : (picture:File) => {
            return uploadAttachement(apartment.value._id, picture);
          },
          remove : (pictureID:string) => {
            return removeAttachement(apartment.value._id, pictureID);
          }
        }
      }
      else {
        options = {
          labels : {
            isInBrowser : app.$t('agency.apartments.apartments.create.picturesInBrowser') as string
          }
        }
      }
      return options;
    }

    const getApartmentTitle = (apartment:any) => {
      return app.$agency.apartment.getApartmentTitle(apartment);
    }

    const onFormSubmit = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      laddaSubmit!.start();

      if(apartment.value) {
        updateApartment().then((result:any) => {
          if(result.updated) {
            context.emit('apartment-updated', result.apartment);
          }
          laddaSubmit!.stop();
        })
      }
      else {
        createApartment().then((result:any) => {
          if(result.created) {
            context.emit('apartment-created', result.apartment);
          }
          laddaSubmit!.stop();
        })        
      }
    }

    const onPicturesUpdated = (pictures:any[]) => {
      context.emit('apartment-updated', {
        ...apartment.value,
        pictures : pictures
      });
    }

    const onAttachementsUpdated = (attachements:any[]) => {
      context.emit('attachements-updated', {
        ...apartment.value,
        attachements : attachements
      });
    }

    watch(
      apartment,
      (val:any, oldVal:any) => {
        // We update the form with new apartment
        updateApartmentFormForUpdate(apartment.value);
      },
      { deep: true }
    )

    watch(
      apartmentPictures,
      (val:any, oldVal:any) => {
        // We update the current apartment with new list of pictures
        onPicturesUpdated(val);
      },
      { deep: true }
    )

    watch(
      apartmentAttachements,
      (val:any, oldVal:any) => {
        // We update the current apartment with new list of attachements
        onAttachementsUpdated(val);
      },
      { deep: true }
    )

    return {
      apartment,
      isApartmentListLoading,
      apartments,
      availableLanguageCodes,
      getSummaryLocalizedContentOptions,
      getDescriptionLocalizedContentOptions,
      getListPicturesOptions,
      getListAttachementsOptions,
      getApartmentTitle,
      addressOptions,
      apartmentForm,
      apartmentPictures,
      apartmentAttachements,
      buttonLabel,
      onFormSubmit,
      createFromApartment,
      allowCreateFrom,
      createFromForm
    }
  }
})
</script>