<template>
  
    <form role="form" @submit="onFormSubmit" name="publish-apartment">

      <h4>{{$t('hospitality.apartment.publish.apartment')}}</h4>
      <ul>
        <li>{{$t('hospitality.apartment.info.title')}}: {{getLocalizedText(apartment.title)}}
        <li>{{$t('hospitality.apartment.info.isActivated')}}: <i :class="['fa','fa-check',apartment.isActivated ? 'active' : 'disabled']"></i></li>  
        <li>{{$t('hospitality.apartment.list.headers.thumbnails')}}: {{ apartment.pictures.length }}</li>
        <li>{{$t('hospitality.apartment.list.headers.attachements')}}: {{ apartment.attachements.length }}</li>
        <li>{{$t('hospitality.apartment.info.summary')}}: {{ getLocalizedText(apartment.summary) }}</li>
        <li>{{$t('hospitality.apartment.info.lastUpdateDateTime')}}: {{ formatDateTime(apartment.lastUpdateDateTime) }}</li>
      </ul>


      <div class="production-apartment">
        <h4>{{$t('hospitality.apartment.publish.production-apartment')}}</h4>
        <ul v-if="productionApartment">
          <li>{{$t('hospitality.apartment.info.title')}}: {{getLocalizedText(productionApartment.title)}}
          <li>{{$t('hospitality.apartment.info.isActivated')}}: <i :class="['fa','fa-check',productionApartment.isActivated ? 'active' : 'disabled']"></i></li>  
          <li>{{$t('hospitality.apartment.list.headers.thumbnails')}}: {{ productionApartment.pictures.length }}</li>
          <li>{{$t('hospitality.apartment.list.headers.attachements')}}: {{ productionApartment.attachements.length }}</li>
          <li>{{$t('hospitality.apartment.info.summary')}}: {{ getLocalizedText(productionApartment.summary) }}</li>
          <li>{{$t('hospitality.apartment.info.lastUpdateDateTime')}}: {{ formatDateTime(productionApartment.lastUpdateDateTime) }}</li>
        </ul>
        <span v-if="productionApartment == null">{{$t('hospitality.apartment.publish.no-production-apartment')}}</span>
      </div>
      
      <button class="btn btn-primary ladda-button publish-apartment" data-style="zoom-in" type="submit">{{$t('hospitality.apartment.publish.button')}}</button>
    </form>

</template>

<style scoped>
  .fa-check.active, .fa-user-circle-o.active {
    color:green;
  }
  .fa-check.disabled, .fa-user-circle-o.disabled {
    color:red;
  }

  
</style>

<script lang="ts">
import { toRefs, Ref, ref, defineComponent, PropType, computed, onMounted } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as Ladda from 'ladda';
import { languagesTypes } from '@fwk-client/store/types';
import { formatDate } from '@igotweb-node-api-utils/formatter';

import { useAgencyAdmin } from '../../../composables/useAgencyAdmin';
import { useApartmentAdmin } from '../../../composables/useApartmentAdmin';

export default defineComponent({
  props: {
    apartment: Object as PropType<any>
  },
  components: {
      
  },
  setup(props, context) {
      const app = getApp();
      const $router = useRouter();
      const $store = useStore();

      const { selectedAgency, callAgencyAdmin } = useAgencyAdmin(props, context);
      const { getPublishTargetApartment, publishApartment } = useApartmentAdmin(props, context);

      const productionApartment:Ref<any> = ref(undefined);

      const { apartment } = toRefs(props);

      var laddaSubmit:Ladda.LaddaButton|null = null;

      onMounted(() => {
        var publishButton:HTMLButtonElement|null = document.querySelector( 'form[name=publish-apartment] button.ladda-button.publish-apartment' );
        laddaSubmit = Ladda.create(publishButton!);
      })

      var isProductionApartmentLoading:boolean = false;
      const getProductionApartment = () => {
        isProductionApartmentLoading = true;
        getPublishTargetApartment(apartment.value._id).then((apartment:any) => {
          productionApartment.value = apartment;
          isProductionApartmentLoading = false;
        });
      }
      // We load the production apartment.
      getProductionApartment();

      const onFormSubmit = (evt:Event) => {
        evt.preventDefault();

        laddaSubmit!.start();

        return publishApartment(apartment.value._id).then((result:any) => {
          laddaSubmit!.stop();
          if(result.published) {  
            productionApartment.value = result.apartment;
          }
        })
      }

    const currentLanguageCode = $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];
    
    const formatDateTime = (date:Date) => {
      return formatDate(date, currentLanguageCode);
    }

    return {
      formatDateTime,
      isProductionApartmentLoading,
      apartment,
      productionApartment,
      onFormSubmit
    }
  }
})
</script>